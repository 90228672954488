// config.js
const config = {
    //apiUrl: 'http://localhost:3001',
    apiUrl: 'https://cargoasem17.kz:3001',
   
    // nameCargo: 'TURKESTAN KARGO'
    nameCargo: 'CARGO ASEM17'
  };
  
  
export default config;
  
